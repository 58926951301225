
import React, { useState, useEffect, useRef } from "react";
import { Input, Checkbox, Select, Form, InputNumber, DatePicker, Button, Collapse, message, Divider, Radio } from 'antd';
import { connect } from 'react-redux';
import UserSearchSelect from "../../components/user_search_select";
import { JsonEditor as Editor } from 'jsoneditor-react';
import UserAdvanceFilter from "../../components/user_advance_filter";
import { Menu as MenuAction } from '../../store/global/actions'
import 'jsoneditor-react/es/editor.min.css';
import dayjs from "dayjs"
import Ajv from 'ajv';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

const ajv = new Ajv({ allErrors: true, verbose: true });
const { Panel } = Collapse;
function PushForm({ channel, ...props }) {
    // 模板数据
    const [templateData, setTemplateData] = useState([])
    // 渠道数据
    const [channelData, setChannelData] = useState(null)
    // 版本数据
    const [versionData, setVersionData] = useState(null)
    // 正在提交
    const [submiting, setSubmiting] = useState(false)
    // 是否显示ios的表单
    const [showiOS, setShowiOS] = useState(true)
    // 是否显示安卓的表单
    const [showAndroid, setShowAndroid] = useState(true)
    // json编辑器的关联
    const jsonEditorRef = useRef()
    // json编辑器的关联
    const jsonViewerRef = useRef()
    // 表单关联
    const [formRef] = Form.useForm();
    // 当前表单的值
    const [audienceType, setAudienceType] = useState("filter")
    // 用户高阶筛选器的筛选结果容量
    const [userAdvanceFilterResult, setUserAdvanceFilterResult] = useState(null)


    props.setActiveKey("CACHE")

    useEffect(() => {
        pushEvent("reload-templates", {})
        channel.on("template_data", ({ data }) => setTemplateData(data))
        channel.push("channel-data", {})
            .receive("ok", ({ data }) => setChannelData(data))

        channel.push("version-data", {})
            .receive("ok", ({ data }) => setVersionData(data?.entries))


        // 由参数传入的模板id，并使用这个模板
        const handleOnTemplateLoad = (template_id) => {
            pushEvent("find-template-by-id", { id: template_id })
                .receive("ok", (template) => {
                    handleSetTemplate(template)
                })
        }
        if (!!props.templateID)
            handleOnTemplateLoad(props.templateID)
    }, [channel])



    // 从下拉列表选择模板
    const handleOnTemplateSelect = (template_id) => {
        const template = templateData.find(item => item.id === template_id)
        handleSetTemplate(template)
    }

    // 选择模板 
    const handleSetTemplate = (template) => {
        // 格式化时间，以免出错
        if (!!template.notification.android && template.notification.android.show_begin_time)
            template.notification.android.show_begin_time = dayjs(template.notification.android.show_begin_time)

        if (!!template.notification.android && template.notification.android.show_end_time)
            template.notification.android.show_end_time = dayjs(template.notification.android.show_end_time)

        // 需要单独设置json编辑器的值
        jsonEditorRef.current.jsonEditor.set(template?.data?.extras || {})

        const formData = {
            name: template.name,
            platform: template.platform.value,
            alert: template.alert,
            notification: template.notification,
            extras: template.data?.extras
        }
        formRef.setFieldsValue(formData)

        viewerSet(formData)
    }


    const pushEvent = (event, params, opts = null) => {
        return channel.push(event, params, 600_000)
    }

    const viewerSet = (allValues) => {
        if (!!allValues.notification?.ios)
            allValues.notification.ios["extras"] = allValues.extras
        if (!!allValues.notification?.android)
            allValues.notification.android["extras"] = allValues.extras

        delete allValues.extras
        jsonViewerRef.current.jsonEditor.set(allValues || {})
        jsonViewerRef.current.jsonEditor.expandAll && jsonViewerRef.current.jsonEditor.expandAll()
    }

    // 处理表单自发改变
    const handleOnValuesChange = (changedFields, allValues) => {
        if (changedFields.platform) {
            setShowiOS(changedFields.platform.indexOf("ios") > -1)
            setShowAndroid(changedFields.platform.indexOf("android") > -1)
        }
        viewerSet(allValues)
    }

    // 处理重置表单
    const handleReset = () => {
        formRef.resetFields()
    }

    // 处理存为模板
    const handleOnSaveTemplate = () => {
        let data = formRef.getFieldsValue();

        data["data"] = { extras: data.extras }

        if (!data.name) {
            return message.warning("保存模板时，推送原因必填")
        }

        if (!!data.notification?.ios)
            data.notification.ios["extras"] = data.extras
        if (!!data.notification?.android)
            data.notification.android["extras"] = data.extras

        pushEvent("save-template", { id: props.templateID, ...data })
            .receive("ok", () => {
                message.success("保存成功")
            })
    }

    // 处理推送
    const handlePush = (values) => {
        setSubmiting(true)

        if (!!values.notification.ios)
            values.notification.ios["extras"] = values.extras
        if (!!values.notification.android)
            values.notification.android["extras"] = values.extras

        delete values.extras

        pushEvent("push", values)
            .receive("ok", () => {
                message.success("推送成功")
                formRef.resetFields()
                setSubmiting(false)
            })
    }

    // 变更推送
    const handleAudienceTypeChange = (e) => {
        setAudienceType(e.target.value)
        switch (e.target.value) {
            case "all":
                formRef.setFieldsValue({ audience: "all" })
                break;
            case "custom":
                formRef.setFieldsValue({ audience: [] })
                break;
            case "custom":
                formRef.setFieldsValue({ audience: {} })
                break;

            default:
                break;
        }

        viewerSet(formRef.getFieldsValue())
    }

    // 用户筛选器提交获取数量
    const handleUserAvdvanceFilterSubmit = (value) => {
        formRef.setFieldsValue({ audience: { memberFilter: value } })

        pushEvent("get-user-count-by-filters", { filters: value })
            .receive("ok", (count) => {
                setUserAdvanceFilterResult(count)
            })
    }

    return <Form form={formRef} name="push-customize" style={{ display: "flex", justifyContent: "space-between" }} onFinish={handlePush} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} autoComplete="off" onValuesChange={handleOnValuesChange}>
        <Collapse defaultActiveKey={["basic"]} style={{ width: "50%", marginRight: 10, backgroundColor: "transparent" }}>
            <Panel header="基本信息" key="basic" style={{ height: "100%" }}>
                <div style={{ width: "100%" }}>
                    <Form.Item label="推送模板" name="template_id" help="快速填写信息">
                        <Select onChange={handleOnTemplateSelect} options={templateData.map((item) => { return { key: item.id, value: item.id, label: item.name } })} />
                    </Form.Item>
                    <Form.Item label="推送原因" name="name" help="非必填，用于记录推送的目的">
                        <Input />
                    </Form.Item>

                    <Form.Item label="目标类别">
                        <Radio.Group buttonStyle="solid" value={audienceType} onChange={handleAudienceTypeChange} options={[{ label: "所有人", value: "all", disabled: false }, { label: "手选", value: "custom" }, { label: "筛选器", value: "filter" }]} optionType="button">
                        </Radio.Group>
                    </Form.Item>

                    {
                        audienceType === "custom" &&
                        <Form.Item label="推送目标" name="audience" rules={[{ required: true, message: '推送目标不能为空' }]}>
                            <UserSearchSelect mode="multiple" placeholder="搜索并选择用户，可多选" />
                        </Form.Item>
                    }

                    {
                        audienceType === "all" &&
                        <Form.Item label="推送目标" hidden name="audience" rules={[{ required: true, message: '消息内容不能为空' }]}>
                            <Input />
                        </Form.Item>
                    }

                    {
                        audienceType === "filter" &&
                        <Form.Item label="推送目标" name="audience" rules={[{ required: true, message: '消息内容不能为空' }]}>
                            <UserAdvanceFilter canPush channelData={channelData} versionData={versionData} className="form" onChange={handleUserAvdvanceFilterSubmit} />
                            {userAdvanceFilterResult !== 0 && userAdvanceFilterResult ? <p>
                                筛选结果用户数：{userAdvanceFilterResult}
                            </p> : <span>无会员</span>}
                        </Form.Item>

                    }

                    <Form.Item label="推送平台" name="platform" rules={[{ required: true, message: '平台必填' }]} initialValue={['ios', 'android']}>
                        <Checkbox.Group options={[{ label: 'iOS', value: 'ios' }, { label: '安卓', value: 'android' }]}  ></Checkbox.Group>
                    </Form.Item>

                    <Form.Item label="推送内容" name={["notification", "alert"]} rules={[{ required: true, message: '消息内容不能为空' }]}>
                        <Input.TextArea />
                    </Form.Item>

                    {/* <Form.Item label="附加参数" name="extras" initialValue={{}}>
                        <Input />
                    </Form.Item> */}

                    <Form.Item label="附加参数" name="extras" initialValue={{}}>
                        <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
                        <Button.Group block>
                            <Button type="primary" htmlType="submit" loading={submiting}>
                                推送
                            </Button>
                            <Button type="default" onClick={handleReset}>
                                清空
                            </Button>
                            <Button type="link" onClick={handleOnSaveTemplate}>
                                {props.templateID ? "修改本模板" : "保存成模板"}
                            </Button>
                        </Button.Group>
                    </Form.Item>
                    <Divider></Divider>
                    <Form.Item label="请求体">
                        <Editor mode="view" value={{}} ace={ace} mainMenuBar={false} modes={["tree", "preview", "text"]} ref={jsonViewerRef} ajv={ajv} language="zh-CN" />
                    </Form.Item>
                </div>
            </Panel>
        </Collapse>

        <Collapse defaultActiveKey={['1', '2', '3']} style={{ width: "50%", backgroundColor: "transparent" }}>
            {
                showiOS &&
                <Panel header="iOS" key="2">
                    <div style={{ flexGrow: 1 }}>
                        <Form.Item label="推送标题" name={["notification", "ios", "alert", "title"]} >
                            <Input placeholder="非必填，推送的标题" />
                        </Form.Item>
                        <Form.Item label="推送内容" name={["notification", "ios", "alert", "body"]} >
                            <Input.TextArea placeholder="非必填，推送的内容" maxLength={128} />
                        </Form.Item>
                        <Form.Item label="角标" name={["notification", "ios", "badge"]} help="如果不填，表示不改变角标数字，否则把角标数字改为指定的数字；为 0 表示清除。">
                            <InputNumber min={0} max={999} />
                        </Form.Item>
                    </div>
                </Panel>
            }
            {
                showAndroid &&
                <Panel header="Android" key="3">
                    <div style={{ flexGrow: 1 }}>
                        <Form.Item label="推送标题" name={["notification", "android", "title"]} >
                            <Input placeholder="非必填，不填则显示APP的名称。" />
                        </Form.Item>

                        <Form.Item label="推送内容" name={["notification", "android", "alert"]} >
                            <Input.TextArea placeholder="非必填，特定安卓的内容，将覆盖基本信息的内容" />
                        </Form.Item>

                        <Form.Item label="增量角标" name={["notification", "android", "badge_add_num"]} initialValue={1} help="此属性目前仅针对华为 EMUI 8.0 及以上、小米 MIUI 6 及以上设备生效；不填不改变数字">
                            <InputNumber min={0} max={999} />
                        </Form.Item>

                        <Form.Item label="点击通知跳转" name={["notification", "android", "intent", "url"]} initialValue="intent:#Intent;action=android.intent.action.MAIN;component=com.mc.acupoint_3d/com.mc.acupoint_3d.MainActivity;end" help={<div>
                            <p>
                                非必填，使用 intent 里的 url 指定点击通知栏后跳转的目标页面。
                            </p>
                            <p>
                                默认：intent:#Intent;action=android.intent.action.MAIN;end
                            </p>
                            <p>
                                scheme://test?key1=val1&key2=val2
                            </p>
                        </div>}>
                            <Input placeholder="intent:#Intent;action=action路径;component=包名/Activity全名;end" />
                        </Form.Item>

                        <Form.Item label="开始时间" name={["notification", "android", "show_begin_time"]} help="默认立马生效">
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>

                        <Form.Item label="结束时间" name={["notification", "android", "show_end_time"]} >
                            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                        </Form.Item>
                    </div>
                </Panel>
            }
        </Collapse>

    </Form>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.pushChannel
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PushForm)